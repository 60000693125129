<template>
  <div class="reports-page-datatable">
    <el-table :data="data" style="width: 100%; font-size: 11px; padding: 0;" :max-height="screenHeight" class="el-table--mini" show-summary>
      <el-table-column sortable prop="state" label="UF" width="56" />
      <el-table-column sortable prop="quantity_customers" label="Clientes" />
      <el-table-column sortable prop="quantity_invoices" label="Notas" />

      <el-table-column label="Ticket">
        <el-table-column sortable prop="min_ticket" label="Min" />
        <el-table-column sortable prop="average_ticket" label="Médio" />
        <el-table-column sortable prop="max_ticket" label="Max" />
      </el-table-column>

      <el-table-column label="Peso">
        <el-table-column sortable prop="min_weight" label="Min" />
        <el-table-column sortable prop="average_weight" label="Médio" />
        <el-table-column sortable prop="max_weight" label="Max" />
      </el-table-column>

      <el-table-column label="Volume">
        <el-table-column sortable prop="min_volume" label="Min" />
        <el-table-column sortable prop="average_volumes" label="Médio" />
        <el-table-column sortable prop="max_volume" label="Max" />
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import exportJson from '@/helpers/exportXLSX'

export default {
  name: 'report-table-stats-by-state',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Object,
      default: () => {
        return {
          estado: 'state',
          total_clientes: 'quantity_customers',
          total_notas: 'quantity_invoices',
          itens_total: 'total_items',
          itens_min: 'min_item',
          itens_medio: 'average_items',
          itens_max: 'max_item',
          recebiveis_total: 'total_receivables',
          recebiveis_min: 'min_receivable',
          recebiveis_medio: 'average_receivables',
          recebiveis_max: 'max_receivable',
          ticket_total: 'total_ticket',
          ticket_min: 'min_ticket',
          ticket_medio: 'average_ticket',
          ticket_max: 'max_ticket',
          volumes_total: 'total_volumes',
          volumes_min: 'min_volume',
          volumes_medio: 'average_volumes',
          volumes_max: 'max_volume',
          peso_total: 'total_weight',
          peso_min: 'min_weight',
          peso_medio: 'average_weight',
          peso_max: 'max_weight',
        }
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    screenHeight() {
      return (window.innerHeight -260)
    }
  },
  watch: {},
  mounted() {
    this.$emit('loaded')
  },
  methods: {
    DownloadXLS() {
      exportJson.DownloadXLS(this.data, this.fields)
    },
    DownloadCSV() {
      exportJson.DownloadCSV(this.data, this.fields)
    },
  },
}
</script>
